<template>
  <v-card class="border-radius-xl card-shadow mt-10 mx-4" v-if="asuulga">
    <v-progress-linear
      indeterminate
      color="red"
      v-if="loading"
    ></v-progress-linear>
    <h3 class="mx-4 pt-4">
      Сэдэв: <span>{{ asuulga.title }}</span>
    </h3>
    <v-row>
      <v-col>
        <!-- <h4 class="mx-4 text-gray">
          Сурагчийн тоо: {{ asuulga.numberOfparticipants }} /
          <span v-if="asuulga.teachers">{{ asuulga.teachers.length }}</span>
        </h4> -->
      </v-col>
      <!-- <v-col cols="6">
        <v-btn color="red" dark @click="_clearAll">clear</v-btn>
      </v-col> -->

      <v-progress-circular
        indeterminate
        color="red"
        v-if="excelLoading"
      ></v-progress-circular>

      <v-switch
        color="green"
        class="mt-2"
        v-if="loading == false"
        v-model="downloadStatus"
        inset
        :label="downloadStatus ? 'Дэлгэмэл хэлбэрээр' : 'Хураамал хэлбэрээр'"
      ></v-switch>

      <v-btn
        v-if="loading == false"
        @click="_downloadExcel()"
        class="bg-gradient-success white--text mx-6 font-weight-bold"
        large
        >Excel
      </v-btn>

      <v-btn
        v-if="userData.role == 'superadmin' && userData.role == 'schooladmin'"
        class="mx-6 mt-n4 bg-gradient-danger white--text"
        @click="clearResults()"
        >Үр Дүнг Цэвэрлэх</v-btn
      >

      <!-- <v-btn @click="restoreDataFromResults()">Restore Data</v-btn> -->
    </v-row>
    <v-row class="mx-4">
      <v-chip class="bg-gradient-success white--text">Т</v-chip>
      <span class="mt-1 mr-5 ml-1">Тийм</span>
      <v-chip class="bg-gradient-danger white--text">Ү</v-chip>
      <span class="mt-1 mr-5 ml-1">Үгүй</span>
      <v-chip class="bg-gradient-warning white--text">З</v-chip>
      <span class="mt-1 mr-5 ml-1">Заримдаа</span>
    </v-row>
    <v-col cols="6" lg="2" md="3" class="ml-1 d-flex flex-row">
      <v-select
        placeholder="Анги сонгох"
        clearable
        v-model="selectedClassGroup"
        :items="classGroups"
        item-text="fullName"
        item-value="id"
        return-object
      ></v-select>
    </v-col>

    <!-- <span
        class="blue--text"
        v-if="filteredAnswerers && filteredAnswerers.length > 0"
        >{{ filteredAnswerers.length }}</span
      >/{{ results2.length }}
      <span
        class="blue--text"
        v-if="maxNumber && results2 && results2.length > 0"
        >{{ maxNumber }},
      </span>

      <span
        class="green--text"
        v-if="maxNumber && results2 && results2.length > 0"
        >{{ Math.round((results2.length / maxNumber) * 100) }}%</span
      >, Aваx оноо: {{ totalScore }}, Дундаж: {{ _getAverageTotalScore() }},
      Max:{{ maxTotalScore }} -->

    <v-row v-if="asuulga">
      <v-col md="10" lg="12" class="mx-auto">
        <v-row justify="end">
          <v-col cols="6" class="text-end mx-10">
            <!-- <v-btn
              @click="_closeSoril"
              color="error"
              class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 text-uppercase btn-info bg-gradient-info"
            >
              Сорил xааx</v-btn
            > -->
            <!-- <v-btn
              @click="_downloadExcel()"
              class="bg-gradient-success white--text"
            >
              Download Excel
            </v-btn> -->
            <!-- <v-btn
              @click="redirectTime()"
              color="error"
              class="border-radius-sm text-xxs text-white ml-1 shadow-none font-weight-bold px-3 py-1 text-uppercase btn-info bg-gradient-default"
            >
              Цаг эхлүүлэх</v-btn
            > -->
          </v-col>
        </v-row>
      </v-col>
      <v-col md="10" lg="12" class="mx-auto">
        <v-card
          v-for="(teacher, tindex) in asuulga.teachers"
          :key="tindex"
          class="mx-10 asuulga"
        >
          <h3 class="py-2 my-2">
            <span class="grey--text">{{ tindex + 1 }}. </span
            >{{ teacher.firstName }}
          </h3>
          <table v-if="teacher.programs && teacher.programs.length > 0">
            <thead>
              <tr>
                <th>No</th>
                <th>Заадаг бүлэг</th>
                <th>Xичээл заадаг с.тоо</th>
                <th>Xамрагдсан с.тоо</th>
                <th>Xамрагдсан хувь</th>
                <th
                  class="pa-0 text-center"
                  v-for="(question, qindex) in teacher.programs[0].questions"
                  :key="'hq' + qindex"
                >
                  <span>
                    {{ "Асуулт " + (qindex + 1) }}
                  </span>
                  <div class="d-flex flex-row">
                    <v-col
                      cols="4"
                      class="pa-0 pt-1"
                      style="
                        border-top: 1px solid #e0e0e0;
                        border-right: 1px solid #e0e0e0;
                      "
                    >
                      Т
                    </v-col>
                    <v-col
                      cols="4"
                      class="pa-0 pt-1"
                      style="
                        border-top: 1px solid #e0e0e0;
                        border-right: 1px solid #e0e0e0;
                      "
                    >
                      Ү
                    </v-col>
                    <v-col
                      cols="4"
                      class="pa-0 pt-1"
                      style="border-top: 1px solid #e0e0e0"
                      >З</v-col
                    >
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(program, pindex) in teacher.programs" :key="pindex">
                <td>{{ pindex + 1 }}</td>
                <td style="width: 5%">
                  {{ program.name }}
                </td>

                <td v-if="selectedClassGroup" style="width: 5%">
                  {{ program.numberOfStudents }}
                </td>
                <td v-else style="width: 5%">{{ program.numberOfStudents }}</td>
                <td
                  title="Энд дарж хамрагдсан сурагчдаа харах боломжтой"
                  style="width: 5%; color: red; cursor: pointer"
                  @click="getParticipantsData(teacher, pindex)"
                >
                  {{ program.numberOfparticipants }}
                </td>
                <td style="width: 5%">
                  {{
                    (
                      (program.numberOfparticipants /
                        program.numberOfStudents) *
                      100
                    ).toFixed(0)
                  }}%
                </td>
                <template v-if="program.questions">
                  <td
                    class="pa-0"
                    v-for="(question, qindex) in program.questions"
                    :key="'q' + qindex"
                  >
                    <div
                      class="d-flex flex-row text-center"
                      style="line-height: 2.5"
                    >
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="border-right: 1px solid #e0e0e0"
                      >
                        {{ question.yesNumber }}
                      </v-col>
                      <v-col
                        cols="4"
                        class="pa-0 pt-1"
                        style="border-right: 1px solid #e0e0e0"
                      >
                        {{ question.noNumber }}
                      </v-col>
                      <v-col cols="4" class="pa-0 pt-1">{{
                        question.sometimesNumber
                      }}</v-col>
                    </div>
                  </td>
                </template>
                <!-- <td>{{ props.item.programs.length }}</td>
              <td v-if="props.item.programs && props.item.programs.length > 0">
                <tr v-for="(program, pindex) in props.item.programs" :key="pindex">
                  <td>{{ program.name }}</td>
                </tr>
              </td> -->
              </tr>
              <tr>
                <td style="width: 2%; border-right: 1px solid white"></td>
                <td style="border-right: 1px solid white"></td>
                <td style="width: 5%; border-right: 1px solid white">
                  {{ teacher.toBeNumberOfParticipants }}
                </td>
                <td
                  title="Энд дарж хамрагдсан сурагчдаа харах боломжтой"
                  style="
                    border-right: 1px solid white;
                    color: red;
                    cursor: pointer;
                    width: 5%;
                  "
                  @click="getParticipantsData(teacher, null)"
                >
                  {{ teacher.numberOfparticipants }}
                </td>
                <td style="width: 5%">
                  {{
                    (
                      (teacher.numberOfparticipants /
                        teacher.toBeNumberOfParticipants) *
                      100
                    ).toFixed(0)
                  }}%
                </td>
                <td
                  class="pa-0"
                  v-for="(question, qindex) in teacher.questions"
                  :key="'q' + qindex"
                >
                  <div
                    class="d-flex flex-row text-center"
                    style="line-height: 2.5"
                  >
                    <v-col
                      cols="4"
                      class="pa-0 pt-1"
                      style="border-right: 1px solid white"
                    >
                      {{ question.yesNumberTotal }}
                    </v-col>
                    <v-col
                      cols="4"
                      class="pa-0 pt-1"
                      style="border-right: 1px solid white"
                    >
                      {{ question.noNumberTotal }}
                    </v-col>
                    <v-col
                      cols="4"
                      style="border-right: 1px solid white"
                      class="pa-0 pt-1"
                      >{{ question.sometimesTotal }}</v-col
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <h2 v-else class="text-center red--text mb-10">
            Танай сургуулиас оролцоогүй байна!
          </h2> -->
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="8" class="mx-auto">
        <v-data-table
          loading
          loading-text="Мэдээлэл байxгүй байна"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="seeParticipantsDialog"
      v-if="selectedParticipants"
      width="500"
    >
      <v-card class="pa-4">
        <v-data-table
          :items="selectedParticipants"
          :headers="[]"
          hide-default-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header="{ props: {} }">
            <tr>
              <th class="text-start pl-3">No</th>
              <th class="text-start pl-4">Нэр</th>
              <th class="text-start pl-4">Илгээсэн Он сар</th>
            </tr>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>
                {{ props.item.studentFirstName }}.
                <span v-if="props.item.studentLastName">{{
                  props.item.studentLastName
                }}</span>
              </td>
              <td style="font-size: 12px">
                {{ props.item.answeredAt.toDate().toLocaleString() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
import XLSX from "xlsx"; // eslint-disable-line
Vue.use(VueSweetalert2);

const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    asuulgas: null,
    seeParticipantsDialog: false,
    selectedParticipants: null,
    analyzeSchoolScoresDialog: false,
    selectedStudent: null,
    showStudentAnswerDialog: false,
    analyzeScoresDialog: false,
    school: null,
    onlyOurSchool: true,
    downloadLoading: false,
    resultCollectionName: "results-simplified", //simplified
    asuulga: null,
    schools: null,
    maxTotalScore: 0,
    sumOfAverageTotalScores: 0,
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    results: null,
    categories: null,
    loading: true,
    rules: [],
    downloadStatus: false,

    headers: [
      {
        text: "No",
        align: "start",
        sortable: true,
        value: "index",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Xамрагдсан тоо",
        align: "start",
        sortable: true,
        value: "numberOfparticipants",
      },
    ],
    showStudentTimeDialog: false,
    classGroups: null,
    selectedClassGroup: null,
    excelLoading: false,
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    isEyesh() {
      return fb.EYESH_APP;
    },
    totalScore() {
      //return this.categories.reduce((pre, el) => pre + el.totalScore?el.totalScore:0, 0);
      var score = 0;
      if (this.categories) {
        for (var category of this.categories) {
          if (category.totalScore) {
            score = score + category.totalScore;
          }
        }
      }

      return Math.round(score);
    },
    results2() {
      if (this.results)
        return this.results.filter((student) => student.skipped != true);
      else return [];
    },
    filteredAnswerers() {
      if (this.results && this.userData.ppschool) {
        if (this.onlyOurSchool) {
          var tmp = [];
          var counter = 0;

          for (var result of this.results) {
            if (fb.EYESH_APP) {
              if (
                result.answerer.ref.parent.parent.path == this.userData.ppschool
              ) {
                result.index = ++counter;
                tmp.push(result);
              }
            } else {
              if (result.answerer.ppschool == this.userData.ppschool) {
                result.index = ++counter;
                tmp.push(result);
              }
            }
          }
          return tmp;
        } else return this.results;
      } else if (
        this.userData.role == "aspecialist" &&
        this.userData.schoolIndex &&
        this.results
      ) {
        var tmp2 = [];
        var counter2 = 0;
        for (var xx of this.results) {
          if (xx.answerer.schoolIndex == this.userData.schoolIndex) {
            xx.index = ++counter2;
            tmp2.push(xx);
          }
        }
        return tmp2;
      } else return this.results;
    },
  },
  created() {
    for (var i = 1; i <= 10; i++) {
      this.headers.push({
        text: "q" + i,
        align: "start",
        sortable: true,
        value: "q",
        width: "2%",
      });
    }
    if (this.userData.school) {
      this.userData.school.ref.get().then((doc) => {
        this.school = doc.data();
        this.school.id = doc.id;
        this.school.ref = doc.ref;
      });
    }

    this.userData.school.ref
      .collection("departments")
      .orderBy("index", "asc")
      .get()
      .then((docs) => {
        this.classGroups = [];
        docs.forEach((doc) => {
          doc.ref
            .collection("programs")
            .orderBy("fullName", "asc")
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let classGroup = doc.data();
                classGroup.id = doc.id;
                classGroup.ref = doc.ref;

                this.classGroups.push(classGroup);
              });
            });
        });
      });
    fb.db
      .doc(this.sorilId)
      .get()
      .then((doc) => {
        let asuulga = doc.data();
        asuulga.id = doc.id;
        asuulga.ref = doc.ref;
        asuulga.teachers = null;
        asuulga.ref
          .collection("results")
          .limit(5)
          .onSnapshot((docs) => {
            asuulga.teachers = [];
            docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.programs = null;

              teacher.ref.collection("programs").onSnapshot((docs) => {
                teacher.programs = [];
                teacher.toBeNumberOfParticipants = 0;
                var counter = 0;

                docs.forEach(async (doc) => {
                  let program = doc.data();
                  program.id = doc.id;
                  program.ref = doc.ref;
                  var ids = program.name.split("-");
                  if (ids.length == 1) {
                    await this.userData.school.ref
                      .collection("departments")
                      .doc("department-" + program.name[0] + program.name[1])
                      .collection("programs")
                      .where("fullName", "==", program.name)
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          program.numberOfStudents =
                            doc.data().numberOfStudents;
                        });
                      });
                  } else {
                    await this.userData.school.ref
                      .collection("departments")
                      .doc("department-" + ids[0])
                      .collection("programs")
                      .where("fullName", "==", program.name)
                      .get()
                      .then((docs) => {
                        docs.forEach((doc) => {
                          program.numberOfStudents =
                            doc.data().numberOfStudents;
                        });
                      });
                  }

                  teacher.programs.push(program);
                  teacher.toBeNumberOfParticipants =
                    teacher.toBeNumberOfParticipants + program.numberOfStudents;

                  if (docs.size == ++counter) {
                    setTimeout(() => {
                      this.loading = false;
                    }, 5000);
                  } else {
                    this.loading = true;
                  }
                  console.log(docs.size, counter);
                });
              });
              asuulga.teachers.push(teacher);
            });
          });
        this.asuulga = asuulga;
      });

    this.userData.school.ref
      .collection("asuulga")
      .get()
      .then(async (docs) => {
        this.asuulgas = [];
        await docs.forEach(async (doc) => {
          let asuulga = doc.data();

          asuulga.results = null;
          await doc.ref
            .collection("results")
            .get()
            .then((docs) => {
              console.log("results size" + docs.size);
              asuulga.results = [];
              docs.forEach(async (doc) => {
                let result = doc.data();
                result.programs = null;
                await doc.ref
                  .collection("programs")
                  .get()
                  .then((docs) => {
                    result.programs = [];
                    docs.forEach((doc) => {
                      let program = doc.data();
                      result.programs.push(program);
                    });
                  });
                asuulga.results.push(result);
              });
            });

          this.asuulgas.push(asuulga);
        });
      });
  },
  watch: {
    selectedClassGroup(val) {
      if (val) {
        fb.db
          .doc(this.sorilId)
          .get()
          .then((doc) => {
            let asuulga = doc.data();
            asuulga.id = doc.id;
            asuulga.ref = doc.ref;

            asuulga.teachers = null;

            asuulga.ref.collection("results").onSnapshot((docs) => {
              asuulga.teachers = [];

              docs.forEach(async (doc) => {
                let teacher = doc.data();
                teacher.id = doc.id;
                teacher.ref = doc.ref;
                teacher.programs = null;
                teacher.toBeNumberOfParticipants = 0;

                await teacher.ref.collection("programs").onSnapshot((docs) => {
                  docs.forEach(async (doc) => {
                    var classGroup = this.classGroups.find(
                      (classGroup) => classGroup.fullName == doc.data().name
                    );

                    teacher.toBeNumberOfParticipants =
                      teacher.toBeNumberOfParticipants +
                      classGroup.numberOfStudents;
                  });
                });
                await teacher.ref
                  .collection("programs")
                  .doc(val.fullName)
                  .onSnapshot(async (doc) => {
                    if (doc.exists) {
                      teacher.programs = [];

                      let program = doc.data();
                      program.id = doc.id;
                      program.ref = doc.ref;
                      program.numberOfStudents =
                        this.selectedClassGroup.numberOfStudents;

                      teacher.programs = [program];
                    }
                    if (teacher.programs && teacher.programs.length > 0)
                      asuulga.teachers.push(teacher);
                  });
              });
            });
            this.asuulga = asuulga;
            this.loading = false;
          });
      } else {
        fb.db
          .doc(this.sorilId)
          .get()
          .then((doc) => {
            let asuulga = doc.data();
            asuulga.id = doc.id;
            asuulga.ref = doc.ref;
            asuulga.teachers = null;
            asuulga.ref
              .collection("results")
              .limit(5)
              .onSnapshot((docs) => {
                asuulga.teachers = [];
                docs.forEach((doc) => {
                  let teacher = doc.data();
                  teacher.id = doc.id;
                  teacher.ref = doc.ref;
                  teacher.programs = null;

                  teacher.ref.collection("programs").onSnapshot((docs) => {
                    teacher.programs = [];
                    teacher.toBeNumberOfParticipants = 0;
                    var counter = 0;

                    docs.forEach(async (doc) => {
                      let program = doc.data();
                      program.id = doc.id;
                      program.ref = doc.ref;
                      var ids = program.name.split("-");

                      if (ids.length == 1) {
                        await this.userData.school.ref
                          .collection("departments")
                          .doc(
                            "department-" + program.name[0] + program.name[1]
                          )
                          .collection("programs")
                          .where("fullName", "==", program.name)
                          .get()
                          .then((docs) => {
                            docs.forEach((doc) => {
                              program.numberOfStudents =
                                doc.data().numberOfStudents;
                            });
                          });
                      } else {
                        await this.userData.school.ref
                          .collection("departments")
                          .doc("department-" + ids[0])
                          .collection("programs")
                          .where("fullName", "==", program.name)
                          .get()
                          .then((docs) => {
                            docs.forEach((doc) => {
                              program.numberOfStudents =
                                doc.data().numberOfStudents;
                            });
                          });
                      }

                      teacher.programs.push(program);
                      teacher.toBeNumberOfParticipants =
                        teacher.toBeNumberOfParticipants +
                        program.numberOfStudents;

                      console.log(docs.size, ++counter);
                      if (docs.size == ++counter) {
                        setTimeout(() => {
                          this.loading = false;
                        }, 1000);
                      } else {
                        this.loading = true;
                      }
                    });
                  });
                  asuulga.teachers.push(teacher);
                });
              });
            this.asuulga = asuulga;
          });
      }
    },
  },
  methods: {
    async restoreDataFromResults() {
      // docs.forEach((doc) => {
      //   if (doc.data().teacherName) {
      //     var ids = doc.id.split("-");
      //     this.userData.school.ref
      //       .collection("students")
      //       .doc(ids[0])
      //       .get()
      //       .then((docc) => {
      //         doc.ref.update({
      //           classGroupName: docc.data()["STUDENT_GROUP_NAME"],
      //           departmentIndex: Number(docc.data()["departmentName-2022"]),
      //           studentFirstName: docc.data()["firstName"],
      //           studentLastName: docc.data()["lastName"],
      //           studentRef: docc.ref,
      //           teacherName: ids[1],
      //         });
      //       });
      //   }
      // });

      var counter = 1;

      var group1;
      var group2;
      var group3;
      var group4;
      var group5;
      var group6;
      var group7;

      var batch1 = fb.db.batch();
      var batch2 = fb.db.batch();
      var batch3 = fb.db.batch();
      var batch4 = fb.db.batch();
      var batch5 = fb.db.batch();
      var batch6 = fb.db.batch();
      var batch7 = fb.db.batch();

      await this.asuulga.ref
        .collection("answered")
        .get()
        .then((docs) => {
          console.log(docs.size);
          var list = [];
          docs.forEach(async (doc) => {
            if (doc.data().teacherName == undefined) {
              var ids = doc.id.split("-");
              await this.userData.school.ref
                .collection("students")
                .doc(ids[0])
                .get()
                .then((docc) => {
                  list.push({
                    classGroupName: docc.data()["STUDENT_GROUP_NAME"],
                    departmentIndex: Number(docc.data()["departmentName-2022"]),
                    studentFirstName: docc.data()["firstName"],
                    studentLastName: docc.data()["lastName"],
                    studentRef: docc.ref,
                    teacherName: ids[1],
                    ref: doc.ref,
                  });
                  console.log(counter++);
                });
            } else {
              console.log(counter++);
            }

            if (counter == docs.size) {
              if (list && list.length > 0) {
                console.log(list.length);

                if (list.length < 500) {
                  group1 = list;
                } else if (list.length < 1000) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500);
                } else if (list.length < 1500) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500, 1000);
                  group3 = list.slice(1000);
                } else if (list.length < 2000) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500, 1000);
                  group3 = list.slice(1000, 1500);
                  group4 = list.slice(1500);
                } else if (list.length < 2500) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500, 1000);
                  group3 = list.slice(1000, 1500);
                  group4 = list.slice(1500, 2000);
                  group5 = list.slice(2000);
                } else if (list.length < 3000) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500, 1000);
                  group3 = list.slice(1000, 1500);
                  group4 = list.slice(1500, 2000);
                  group5 = list.slice(2000, 2500);
                  group6 = list.slice(2500);
                } else if (list.length < 3500) {
                  group1 = list.slice(0, 500);
                  group2 = list.slice(500, 1000);
                  group3 = list.slice(1000, 1500);
                  group4 = list.slice(1500, 2000);
                  group5 = list.slice(2000, 2500);
                  group6 = list.slice(2500, 3000);
                  group7 = list.slice(3000);
                } else {
                  alert("Out of Bounds");
                }

                if (list.length < 500) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                } else if (list.length < 1000) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                } else if (list.length < 1500) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                  group3.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch3.update(ref, obj);
                  });
                } else if (list.length < 2000) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                  group3.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch3.update(ref, obj);
                  });
                  group4.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch4.update(ref, obj);
                  });
                } else if (list.length < 2500) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                  group3.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch3.update(ref, obj);
                  });
                  group4.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch4.update(ref, obj);
                  });
                  group5.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch5.update(ref, obj);
                  });
                } else if (list.length < 3000) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                  group3.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch3.update(ref, obj);
                  });
                  group4.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch4.update(ref, obj);
                  });
                  group5.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch5.update(ref, obj);
                  });
                  group6.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch6.update(ref, obj);
                  });
                } else if (list.length < 3500) {
                  group1.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch1.update(ref, obj);
                  });
                  group2.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch2.update(ref, obj);
                  });
                  group3.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch3.update(ref, obj);
                  });
                  group4.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch4.update(ref, obj);
                  });
                  group5.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch5.update(ref, obj);
                  });
                  group6.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch6.update(ref, obj);
                  });
                  group7.forEach((obj) => {
                    const ref = obj.ref;
                    delete obj.ref;
                    batch7.update(ref, obj);
                  });
                }

                if (group1 && group1.length > 0)
                  batch1.commit().then(() => {
                    console.log("batch1 done");
                  });
                if (group2 && group2.length > 0)
                  batch2.commit().then(() => {
                    console.log("batch2 done");
                  });
                if (group3 && group3.length > 0)
                  batch3.commit().then(() => {
                    console.log("batch3 done");
                  });
                if (group4 && group4.length > 0)
                  batch4.commit().then(() => {
                    console.log("batch4 done");
                  });
                if (group5 && group5.length > 0)
                  batch5.commit().then(() => {
                    console.log("batch5 done");
                  });
                if (group6 && group5.length > 0)
                  batch6.commit().then(() => {
                    console.log("batch6 done");
                  });
                if (group7 && group5.length > 0)
                  batch7.commit().then(() => {
                    console.log("batch7 done");
                  });
              }
            }
          });
        });
    },
    getParticipantsData(teacher, pindex) {
      if (pindex != null) {
        this.asuulga.ref
          .collection("answered")
          .where("teacherName", "==", teacher.firstName)
          .where("classGroupName", "==", teacher.programs[pindex].name)
          .get()
          .then((docs) => {
 
            this.selectedParticipants = [];
            docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
 
              this.selectedParticipants.push(stud);
            });
            this.seeParticipantsDialog = !this.seeParticipantsDialog;
            console.log(this.selectedParticipants);
          });
      } else {
        this.asuulga.ref
          .collection("answered")
          .where("teacherName", "==", teacher.firstName)
          .get()
          .then((docs) => {
 
            this.selectedParticipants = [];
            docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;
 
              this.selectedParticipants.push(stud);
            });
            this.seeParticipantsDialog = !this.seeParticipantsDialog;
            console.log(this.selectedParticipants);
          });
      }
    },
    clearResults() {
      var yes = confirm("Та үүнийг устгахдаа итгэлтэй байна уу");
      if (yes) {
        this.asuulga.ref
          .collection("results")
          .get()
          .then((docs) => {
            docs.forEach(async (doc) => {
              await doc.ref
                .collection("programs")
                .get()
                .then((docs) => {
                  docs.forEach((doc) => {
                    doc.ref.delete();
                  });
                  doc.ref.delete();
                });
              doc.ref.delete();
            });
          });

        this.asuulga.ref
          .collection("answered")
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              doc.ref.delete();
            });
          });

        this.asuulga.ref
          .collection("currently-answering")
          .get()
          .then((docs) => {
            docs.forEach(async (doc) => {
              await doc.ref
                .collection("categories")
                .get()
                .then((docs) => {
                  docs.forEach(async (doc) => {
                    await doc.ref
                      .collection("questions")
                      .get()
                      .then((docs) => {
                        docs.forEach(async (doc) => {
                          await doc.ref
                            .collection("answers")
                            .get()
                            .then((docs) => {
                              docs.forEach((doc) => {
                                doc.ref.delete();
                              });
                            });
                          doc.ref.delete();
                        });
                      });

                    doc.ref.delete();
                  });
                });
              doc.ref.delete();
            });
          });
      }
    },
    async _clearAll() {
      // console.log(this.asuulga.ref.path);
      // var yes = confirm("Delete all results and restart again?");
      // if (yes) {
      //   var batch = fb.db.batch()
      //   var x1 = await this.asuulga.ref.collection("results").get();
      //   x1.docs.forEach(async (doc) => {
      //     var programs = await doc.ref.collection("programs").get();
      //     programs.forEach((program) => {
      //       batch.delete(program.ref);
      //     });
      //     batch.delete(doc.ref);
      //   });
      //   batch.commit();
      //   // batch.commit()
      //   // doc.ref.collection("programs").get().then(docs=>{
      //   //     docs.forEach(doc=>{
      //   //       batch.delete(doc.ref)
      //   //     })
      //   //     batch.delete(doc.ref)
      //   //   })
      // }
    },
    _downloadExcel() {
      if (this.downloadStatus) {
        const wb = XLSX.utils.book_new();

        this.excelLoading = true;

        this.asuulgas.forEach((asuulga) => {
          var results = [];

          var resultCounter = 0;

          var finalResults = {
            numberOfparticipants: 0,
            toBeNumberOfParticipants: 0,
            questions: [],
          };

          asuulga.results.forEach((result) => {
            if (resultCounter > 0) {
              var data2 = {
                "Багшийн Нэр": "Багшийн Нэр",
                No: "No",
                "Заадаг бүлэг": "Заадаг бүлэг",
                "Xичээл заадаг с.тоо": "Xичээл заадаг с.тоо",
                "Xамрагдсан с.тоо": "Xамрагдсан с.тоо",
                "Xамрагдсан хувь": "Xамрагдсан хувь",
              };
              for (var j = 1; j <= result.questions.length; j++) {
                data2["Асуулт " + j + " Тийм"] = "Асуулт " + j + " Тийм";
                data2["Асуулт " + j + " Үгүй"] = "Асуулт " + j + " Үгүй";
                data2["Асуулт " + j + " Заримдаа"] =
                  "Асуулт " + j + " Заримдаа";
              }
              results.push(data2);
            }
            result.toBeNumberOfParticipants = 0;

            var programCounter = 0;
            result.programs.forEach((program) => {
              var found = this.classGroups.find(
                (classGroup) => classGroup.fullName == program.name
              );
              result.toBeNumberOfParticipants =
                result.toBeNumberOfParticipants +
                Number(found.numberOfStudents);

              program.toBeNumberOfParticipants = Number(found.numberOfStudents);

              var data;
              if (programCounter == 0) {
                data = {
                  "Багшийн Нэр": result.firstName,
                  No: programCounter + 1,
                  "Заадаг бүлэг": program.name,
                  "Xичээл заадаг с.тоо": program.toBeNumberOfParticipants,
                  "Xамрагдсан с.тоо": program.numberOfparticipants,
                  "Xамрагдсан хувь":
                    (
                      (program.numberOfparticipants /
                        program.toBeNumberOfParticipants) *
                      100
                    ).toFixed(0) + "%",
                };
              } else {
                data = {
                  "Багшийн Нэр": "",
                  No: programCounter + 1,
                  "Заадаг бүлэг": program.name,
                  "Xичээл заадаг с.тоо": program.toBeNumberOfParticipants,
                  "Xамрагдсан с.тоо": program.numberOfparticipants,
                  "Xамрагдсан хувь":
                    (
                      (program.numberOfparticipants /
                        program.toBeNumberOfParticipants) *
                      100
                    ).toFixed(0) + "%",
                };
              }

              for (var i = 1; i <= program.questions.length; i++) {
                data["Асуулт " + i + " Тийм"] =
                  program.questions[i - 1].yesNumber;
                data["Асуулт " + i + " Үгүй"] =
                  program.questions[i - 1].noNumber;
                data["Асуулт " + i + " Заримдаа"] =
                  program.questions[i - 1].sometimesNumber;
              }
              results.push(data);
              programCounter++;
            });

            var data = {
              "Багшийн Нэр": "",
              No: "",
              "Заадаг бүлэг": "Бүгд",
              "Xичээл заадаг с.тоо": result.toBeNumberOfParticipants,
              "Xамрагдсан с.тоо": result.numberOfparticipants,
              "Xамрагдсан хувь":
                (
                  (result.numberOfparticipants /
                    result.toBeNumberOfParticipants) *
                  100
                ).toFixed(0) + "%",
            };

            finalResults.numberOfparticipants =
              finalResults.numberOfparticipants + result.numberOfparticipants;
            finalResults.toBeNumberOfParticipants =
              finalResults.toBeNumberOfParticipants +
              result.toBeNumberOfParticipants;

            for (var i = 1; i <= result.questions.length; i++) {
              if (!finalResults.questions[i - 1]) {
                finalResults.questions[i - 1] = {};
              }
              if (!finalResults.questions[i - 1].yesNumberTotal) {
                finalResults.questions[i - 1].yesNumberTotal =
                  result.questions[i - 1].yesNumberTotal;
              } else {
                finalResults.questions[i - 1].yesNumberTotal =
                  finalResults.questions[i - 1].yesNumberTotal +
                  result.questions[i - 1].yesNumberTotal;
              }

              if (!finalResults.questions[i - 1].noNumberTotal) {
                finalResults.questions[i - 1].noNumberTotal =
                  result.questions[i - 1].noNumberTotal;
              } else {
                finalResults.questions[i - 1].noNumberTotal =
                  finalResults.questions[i - 1].noNumberTotal +
                  result.questions[i - 1].noNumberTotal;
              }

              if (!finalResults.questions[i - 1].sometimesTotal) {
                finalResults.questions[i - 1].sometimesTotal =
                  result.questions[i - 1].sometimesTotal;
              } else {
                finalResults.questions[i - 1].sometimesTotal =
                  finalResults.questions[i - 1].sometimesTotal +
                  result.questions[i - 1].sometimesTotal;
              }

              data["Асуулт " + i + " Тийм"] =
                result.questions[i - 1].yesNumberTotal;
              data["Асуулт " + i + " Үгүй"] =
                result.questions[i - 1].noNumberTotal;
              data["Асуулт " + i + " Заримдаа"] =
                result.questions[i - 1].sometimesTotal;
            }
            results.push(data);
            resultCounter++;
          });

          var data3 = {
            "Багшийн Нэр": "Багшийн Нэр",
            No: "No",
            "Заадаг бүлэг": "Заадаг бүлэг",
            "Xичээл заадаг с.тоо": "Xичээл заадаг с.тоо",
            "Xамрагдсан с.тоо": "Xамрагдсан с.тоо",
            "Xамрагдсан хувь": "Xамрагдсан хувь",
          };
          var data4 = {
            "Багшийн Нэр": "Бүгд",
            No: "",
            "Заадаг бүлэг": "Бүгд",
            "Xичээл заадаг с.тоо": finalResults.toBeNumberOfParticipants,
            "Xамрагдсан с.тоо": finalResults.numberOfparticipants,
            "Xамрагдсан хувь":
              (
                (finalResults.numberOfparticipants /
                  finalResults.toBeNumberOfParticipants) *
                100
              ).toFixed(0) + "%",
          };

          for (var j = 1; j <= asuulga.results[0].questions.length; j++) {
            data3["Асуулт " + j + " Тийм"] = "Асуулт " + j + " Тийм";
            data3["Асуулт " + j + " Үгүй"] = "Асуулт " + j + " Үгүй";
            data3["Асуулт " + j + " Заримдаа"] = "Асуулт " + j + " Заримдаа";

            data4["Асуулт " + j + " Тийм"] =
              finalResults.questions[j - 1].yesNumberTotal;
            data4["Асуулт " + j + " Үгүй"] =
              finalResults.questions[j - 1].noNumberTotal;
            data4["Асуулт " + j + " Заримдаа"] =
              finalResults.questions[j - 1].sometimesTotal;
          }
          results.push(data3);
          results.push(data4);

          const data = XLSX.utils.json_to_sheet(results);

          XLSX.utils.book_append_sheet(
            wb,
            data,
            asuulga.title.substring(0, 10)
          );
        });

        this.excelLoading = false;

        XLSX.writeFile(wb, "asuulgas.xlsx");
      } else {
        const wb = XLSX.utils.book_new();

        this.excelLoading = true;

        this.asuulgas.forEach((asuulga) => {
          var results = [];

          var finalResults = {
            numberOfparticipants: 0,
            toBeNumberOfParticipants: 0,
            questions: [],
          };

          asuulga.results.forEach((result) => {
            result.toBeNumberOfParticipants = 0;

            var classNames = "";

            result.programs.forEach((program) => {
              var found = this.classGroups.find(
                (classGroup) => classGroup.fullName == program.name
              );
              result.toBeNumberOfParticipants =
                result.toBeNumberOfParticipants +
                Number(found.numberOfStudents);

              if (classNames == "") classNames = program.name;
              else classNames = classNames + ", " + program.name;

              program.toBeNumberOfParticipants = Number(found.numberOfStudents);
            });

            var data = {
              "Багшийн Нэр": result.firstName,
              "Заадаг бүлэг": classNames,
              "Xичээл заадаг с.тоо": result.toBeNumberOfParticipants,
              "Xамрагдсан с.тоо": result.numberOfparticipants,
              "Xамрагдсан хувь":
                (
                  (result.numberOfparticipants /
                    result.toBeNumberOfParticipants) *
                  100
                ).toFixed(0) + "%",
            };

            finalResults.numberOfparticipants =
              finalResults.numberOfparticipants + result.numberOfparticipants;
            finalResults.toBeNumberOfParticipants =
              finalResults.toBeNumberOfParticipants +
              result.toBeNumberOfParticipants;

            for (var i = 1; i <= result.questions.length; i++) {
              if (!finalResults.questions[i - 1]) {
                finalResults.questions[i - 1] = {};
              }
              if (!finalResults.questions[i - 1].yesNumberTotal) {
                finalResults.questions[i - 1].yesNumberTotal =
                  result.questions[i - 1].yesNumberTotal;
              } else {
                finalResults.questions[i - 1].yesNumberTotal =
                  finalResults.questions[i - 1].yesNumberTotal +
                  result.questions[i - 1].yesNumberTotal;
              }

              if (!finalResults.questions[i - 1].noNumberTotal) {
                finalResults.questions[i - 1].noNumberTotal =
                  result.questions[i - 1].noNumberTotal;
              } else {
                finalResults.questions[i - 1].noNumberTotal =
                  finalResults.questions[i - 1].noNumberTotal +
                  result.questions[i - 1].noNumberTotal;
              }

              if (!finalResults.questions[i - 1].sometimesTotal) {
                finalResults.questions[i - 1].sometimesTotal =
                  result.questions[i - 1].sometimesTotal;
              } else {
                finalResults.questions[i - 1].sometimesTotal =
                  finalResults.questions[i - 1].sometimesTotal +
                  result.questions[i - 1].sometimesTotal;
              }

              data["Асуулт " + i + " Тийм"] =
                result.questions[i - 1].yesNumberTotal;
              data["Асуулт " + i + " Үгүй"] =
                result.questions[i - 1].noNumberTotal;
              data["Асуулт " + i + " Заримдаа"] =
                result.questions[i - 1].sometimesTotal;
            }
            results.push(data);
          });

          var data4 = {
            "Багшийн Нэр": "Бүгд",
            No: "",
            "Заадаг бүлэг": "Бүгд",
            "Xичээл заадаг с.тоо": finalResults.toBeNumberOfParticipants,
            "Xамрагдсан с.тоо": finalResults.numberOfparticipants,
            "Xамрагдсан хувь":
              (
                (finalResults.numberOfparticipants /
                  finalResults.toBeNumberOfParticipants) *
                100
              ).toFixed(0) + "%",
          };

          for (var k = 1; k <= asuulga.results[0].questions.length; k++) {
            data4["Асуулт " + k + " Тийм"] =
              finalResults.questions[k - 1].yesNumberTotal;
            data4["Асуулт " + k + " Үгүй"] =
              finalResults.questions[k - 1].noNumberTotal;
            data4["Асуулт " + k + " Заримдаа"] =
              finalResults.questions[k - 1].sometimesTotal;
          }
          results.push(data4);

          const data = XLSX.utils.json_to_sheet(results);

          XLSX.utils.book_append_sheet(
            wb,
            data,
            asuulga.title.substring(0, 10)
          );
        });

        this.excelLoading = false;

        XLSX.writeFile(wb, "asuulgas.xlsx");
      }
    },

    resetTest(student) {
      var yes = confirm(student.answerer.firstName + " yстгах уу?");
      if (yes) {
        console.log(this.asuulga.ref, student.id);
        this.asuulga.ref
          .collection("results")
          .doc(student.id)
          .get()
          .then((doc) => {
            console.log(doc);
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("results-simplified")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("daalgavar-analyzed")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
        this.asuulga.ref
          .collection("starting-times")
          .doc(student.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              doc.ref.delete();
            }
          });
      }
    },
    redirectTime() {
      this.showStudentTimeDialog = !this.showStudentTimeDialog;
      // this.$router.push({
      //   name: "XShowAssignmentAllStudentsTime",
      //   params: {
      //     sorilId: this.asuulga.ref.path,
      //   },
      // });
    },
    _closeSoril() {
      console.log(this.asuulga.ref.path);
      this.asuulga.ref
        .collection("school-closed-results")
        .doc(this.userData.school.id)
        .set({
          averageScore: this._getAverageTotalScore()
            ? parseFloat(this._getAverageTotalScore())
            : null,
          maxTotalScore: this.maxTotalScore ? this.maxTotalScore : null,
          totalScore: this.totalScore ? this.totalScore : null,
          numberOfParticipants: this.filteredAnswerers.length
            ? this.filteredAnswerers.length
            : 0,
          createdAt: new Date(),
          createdByRef: this.userData.ref,
          createdByName: this.userData.firstName,
          school: this.school ? this.school : null,
        })
        .then(() => {});
    },
    _getCapitalizedName(name) {
      return name && name.length > 0
        ? name.charAt(0).toUpperCase() + name.slice(1)
        : null;
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            this._getCapitalizedName(
              item.lastName.substring(0, item.prefixName)
            ) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      } else {
        if (item.lastName && item.lastName.length > 0)
          name =
            this._getCapitalizedName(item.lastName[0]) +
            ". " +
            this._getCapitalizedName(item.firstName);
        else name = item.firstName;
      }
      return name;
    },

    _deleteStudentResult(result) {
      var yes = confirm("TODO: Устгаx уу?");
      if (yes) {
        result.ref.parent.parent
          .collection("results")
          .doc(result.id)
          .delete()
          .then(() => {
            result.ref.delete().then(() => {
              console.log("DONE!");
            });
          });
      }
    },
    _publicLink() {
      this.$router.push({
        name: "StudentShowAssignmentAllResults2",
        params: { sorilId: this.asuulga.ref.path },
      });

      //  this.$router.push({
      //   name: "PublicStudentsScore",
      //   params: {
      //     sorilId: this.asuulga.id,
      //   },
      // });
    },
    _saveAllDeepAnalysis() {
      console.log(this.results.length);
      var counter = 0;
      for (var result of this.results) {
        if (result.deepAnalyzed == false) {
          if (!result.skipped) {
            this._deepanalyze(result);
            counter++;
            console.log(counter);
          }
        }
      }
      console.log(counter);
    },
    async _deepanalyze(item) {
      console.log(item.ref.path);
      console.log(item);
      var batch;
      if (this.fromEYESH) {
        batch = fb.dbeyesh800.batch();
      } else {
        batch = fb.db.batch();
      }
      var result = await this.asuulga.ref
        .collection("results")
        .doc(item.ref.id)
        .get();

      var categories = result.data().categories;
      // for(var category of categories){
      var category = categories[0];
      if (category && category.catScored) {
        for (var question of category.questions) {
          for (var answer of question.answers) {
            if (answer.answered) {
              //yyy.push({index:counter,name:answer.name, answered:true})
              var x = {
                questionIndex: question.index,
                answerName: answer.name,
                answered: true,
              };
              var yy = {};
              yy[x.answerName] = fb.firestore.FieldValue.increment(1);
              yy.questionIndex = question.index;
              if (answer.correct) yy.correctAnswer = answer.name;

              var refRef = this.asuulga.ref
                .collection("daalgavar-analyzed")
                .doc(question.index.toString());

              batch.set(refRef, yy, { merge: true });
            } else {
              //yyy.push({index:counter,name:answer.name, answered:false})
            }
          }
        }
        batch.commit().then(() => {
          console.log("donexxx");
          item.ref.update({ deepAnalyzed: true }).then(() => {
            console.log("ok", item.ref.path);
          });
          if (
            this.asuulga.deepAnalyzed == undefined ||
            this.asuulga.deepAnalyzed == false
          )
            this.asuulga.ref.update({ deepAnalyzed: true });
        });
      }
    },
    _getAvgScore2(school) {
      var score = 0;
      var counter = 0;
      if (this.results2) {
        for (var i = 0; i < this.results2.length; i++) {
          var student = this.results2[i];
          if (school.name == student.schoolName) {
            counter++;
            score = score + student.totalScore;
          }
        }
      }
      if (counter > 0) return [(score / counter).toFixed(2), counter];
      else return [score, counter];
    },
    _download() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        var tHeader = [];
        for (var head of this.headers) {
          tHeader.push(head.text);
        }
        var data = [];
        for (const result of this.results) {
          if (!result.skipped) {
            var row = [];
            row.push(result.index);
            row.push(result.answerer.firstName);
            row.push(result.schoolName);
            row.push(result.totalScore);
            row.push(result.percent);

            for (var cat of result.categories) {
              row.push(cat.catScored);
            }
            data.push(row);
          }
        }

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "all_results_" + this.asuulga.name,
          autoWidth: true,
          bookType: "xlsx",
        });
        this.downloadLoading = false;
      });
    },
    _delete(student) {
      var x = "";
      if (student.skipped)
        x =
          student.answerer.firstName + ": тооцоололд оруулаxыг xүсэж байна уу?";
      else
        x =
          student.answerer.firstName + ": тооцооллоос xасаxыг xүсэж байна уу?";

      var yes = confirm(x);
      if (yes) {
        if (!student.skipped) student.ref.update({ skipped: true });
        else student.ref.update({ skipped: !student.skipped });
      }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },
    async retestAllStudents() {
      var counter = 0;
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));

      for (var student of this.filteredAnswerers) {
        this._sendAsuulga(student);
        counter++;
        console.log(student.answerer.firstName, counter);
        await timer(100);
      }
      console.log("DONEEEE");
    },

    _getSimplified(categories, studCategories) {
      var xxCategories = [];
      for (var catIndex = 0; catIndex < categories.length; catIndex++) {
        var categoryTMP = {
          catScored: studCategories[catIndex].catScored
            ? studCategories[catIndex].catScored
            : 0,
          totalScore: categories[catIndex].totalScore
            ? categories[catIndex].totalScore
            : 0,
        };
        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },

    _reportSchool() {
      this.$router.push({
        name: "ReportSchool",
        params: {
          results: this.results,
          asuulga: this.asuulga,
        },
      });
    },

    async _analyzeScores() {
      if (this.categories[0]) {
        var x = await this.categories[0].ref.collection("questions").get();
        if (!x.empty && x.size > 0) {
          this.$router.push({
            name: "AllQuestionsAnalysis",
            params: {
              asuulga: this.asuulga,
              numberOfQuestions: x.size,
            },
          });
        }
      }
    },
    _detail(item) {
      //assignmentsdatabase/jw9gIycm1fuyguz3JeXA/results/h0jYmrkH0WY1ipQrKGhF
      // await assign.ref
      //.collection("results")
      //.doc(this.userData.id)

      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: this.asuulga.ref.path + "/results/" + item.id,
          showSlide: false,
          fromEYESH: this.fromEYESH,
        },
      });
    },
    async _setSchool() {
      for (var result of this.results) {
        if (result.schoolName == undefined) {
          console.log(result.ref.path);

          if (result.answerer["department-2021"]) {
            var ppschool;
            if (fb.EYESH_APP) {
              ppschool = result.answerer["department-2021"].parent.parent.path;
            } else {
              ppschool = result.schoolRef;
            }
            var sch;
            if (this.fromEYESH) {
              sch = await fb.dbeyesh800.doc(ppschool).get();
            } else {
              sch = await fb.db.doc(ppschool).get();
            }
            if (sch.exists) {
              var dataa = {};
              if (sch.ref) dataa.schoolRef = sch.ref;
              if (sch.data().name) dataa.schoolName = sch.data().name;
              result.ref.update(dataa).then(() => {
                console.log(result.ref.path);
              });
            }
          } else {
            if (result.answerer.ppschool) {
              var ppschool2 = result.answerer.ppschool;
              var sch2;
              if (this.fromEYESH) {
                sch2 = await fb.dbeyesh800.doc(ppschool2).get();
              } else {
                sch2 = await fb.db.doc(ppschool2).get();
              }
              if (sch2.exists) {
                var dataa2 = {};
                if (sch2.ref) dataa2.schoolRef = sch2.ref;
                if (sch2.data().name) dataa2.schoolName = sch2.data().name;
                result.ref.update(dataa2).then(() => {
                  console.log(result.ref.path);
                });
              }
            }
          }
        }
      }
    },

    // async findSchools() {
    //   this.schools = [];
    //   var x = await fb.db.collection("schools").get();
    //   if (!x.empty) {
    //     x.docs.forEach((doc) => {
    //       let school = doc.data();
    //       school.id = doc.id;
    //       school.ref = doc.ref;
    //       this.schools.push(school);
    //     });
    //   }
    // },
    _getSchoolName(schoolPath) {
      var x = null;
      if (this.schools) {
        for (var school of this.schools) {
          if (school.ref.path == schoolPath) {
            x = school.name;
            break;
          }
        }
      }

      return x;
    },
    _getAverageTotalScore() {
      if (this.results2)
        return (this.sumOfAverageTotalScores / this.results2.length).toFixed(2);
      else return 0;
    },
    _redirectResultsDetail(sorilId, tabIndex) {
      this.$router.push({
        name: "StudentShowAssignmentAllResultsDetail",
        params: {
          sorilId: sorilId,
          tabIndex: tabIndex,
        },
      });
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.asuulga table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.asuulga td,
.asuulga th {
  font-size: 12px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 2px;
}

.asuulga tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
